import { useMemo } from 'react';
import { Dayjs } from 'dayjs';
import PropTypes from 'prop-types';

import Page from '../../atoms/Page';
import AnimalGroupHeader from '../../molecules/AnimalGroupHeader';
import ConsumptionDisplay from '../../organisms/ConsumptionDisplay';

import './BarnConsumptionTab.scss';

function BarnConsumptionTab({
  loading = false,
  titleSegments = [],
  barnId = '',
  orgId = null,
  openFaultIds = [],
  visibleFeedLineIds = [],
  dateRangeOverride = null,
  animalGroupId = null,
  setAnimalGroupId = undefined,
}) {
  const pageTitleSegments = useMemo(() => ['Consumption', ...titleSegments], []);
  return (
    <Page className="BarnConsumptionTab" titleSegments={pageTitleSegments}>
      <AnimalGroupHeader
        loading={loading}
        barnId={barnId}
        orgId={orgId}
        animalGroupId={animalGroupId}
        setAnimalGroupId={setAnimalGroupId}
      >
        {(dateRange) => (
          <ConsumptionDisplay
            barnId={barnId}
            animalGroupDateRange={dateRangeOverride || dateRange}
            openFaultIds={openFaultIds}
            visibleFeedLineIds={visibleFeedLineIds}
          />
        )}
      </AnimalGroupHeader>
    </Page>
  );
}

BarnConsumptionTab.propTypes = {
  loading: PropTypes.bool,
  titleSegments: PropTypes.arrayOf(PropTypes.string),
  barnId: PropTypes.string,
  orgId: PropTypes.string,
  openFaultIds: PropTypes.arrayOf(PropTypes.string),
  visibleFeedLineIds: PropTypes.arrayOf(PropTypes.string),
  dateRangeOverride: PropTypes.shape({
    from: PropTypes.instanceOf(Dayjs).isRequired,
    to: PropTypes.instanceOf(Dayjs).isRequired,
  }),
  animalGroupId: PropTypes.string,
  setAnimalGroupId: PropTypes.func,
};

export default BarnConsumptionTab;
