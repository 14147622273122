import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import useFormattedInterval from '../../utils/hooks/useFormattedInterval';
import useUser from '../../utils/hooks/useUser';
import AlertTableRowHeader from './AlertTableRowHeader';
import AlertDashboardBadge from '../../atoms/AlertDashboardBadge';
import AlertStatusIndicator from '../../atoms/AlertStatusIndicator';
import { censorTypes, useCensor } from '../../utils/hooks/useCensor';

function AlertTableRow({
  isHeader = false,
  status = '',
  type = '',
  faultId = '',
  faultDate = '',
  faultTime = '',
  durationStartTime = 0,
  durationEndTime = 0,
  locationBarnName = '',
  locationFeedLine = '',
  noFeedTime = 0,
  rootCause = '',
  sortColumn = '',
  sortLevel = 0,
  onColumnClick = () => {},
}) {
  const { formatInterval } = useFormattedInterval();
  const { user } = useUser();
  const { censor } = useCensor();

  const getFormattedIntervalStrings = (startTime, endTime, compact = false) => {
    const intervalStrings = formatInterval(startTime, endTime, compact);

    // If there are 2 or more interval strings, combine all except the first together
    if (intervalStrings.length > 1) {
      return [intervalStrings[0], intervalStrings.slice(1).join(' ')];
    }

    return intervalStrings;
  };

  const renderDuration = () => {
    const [durationText, subDurationText] = getFormattedIntervalStrings(durationStartTime, durationEndTime);

    return (
      <>
        <div className="AlertTable-rowText">{durationText}</div>
        {subDurationText && <div className="AlertTable-rowText--subtext">{subDurationText}</div>}
      </>
    );
  };

  const renderNoFeedTime = () => {
    if (noFeedTime === null) {
      return <div className="AlertTable-rowText">N/A</div>;
    } else if (noFeedTime <= 0) {
      return <div className="AlertTable-rowText">None</div>;
    }

    const currentTime = dayjs.tz().unix();
    const [noFeedTimeText, subNoFeedTimeText] = getFormattedIntervalStrings(currentTime, currentTime + noFeedTime);

    return (
      <>
        <div className="AlertTable-rowText">{noFeedTimeText}</div>
        {subNoFeedTimeText && <div className="AlertTable-rowText--subtext">{subNoFeedTimeText}</div>}
      </>
    );
  };

  if (isHeader) {
    return (
      <div className="AlertTable-row AlertTable-row--header">
        <AlertTableRowHeader
          text="Status"
          sortKey="status"
          sortColumn={sortColumn}
          sortLevel={sortLevel}
          onClick={onColumnClick}
        />
        <AlertTableRowHeader
          text="Type"
          sortKey="type"
          sortColumn={sortColumn}
          sortLevel={sortLevel}
          onClick={onColumnClick}
        />
        <AlertTableRowHeader
          text="Date"
          sortKey="date"
          sortColumn={sortColumn}
          sortLevel={sortLevel}
          onClick={onColumnClick}
        />
        <AlertTableRowHeader
          text="Duration"
          sortKey="duration"
          sortColumn={sortColumn}
          sortLevel={sortLevel}
          onClick={onColumnClick}
        />
        <AlertTableRowHeader
          text="Location"
          sortKey="location"
          sortColumn={sortColumn}
          sortLevel={sortLevel}
          onClick={onColumnClick}
        />
        <AlertTableRowHeader
          text="NoFeed Time"
          sortKey="nofeed_time"
          sortColumn={sortColumn}
          sortLevel={sortLevel}
          onClick={onColumnClick}
        />
        <AlertTableRowHeader
          text="Root Cause"
          sortKey="root_cause"
          sortColumn={sortColumn}
          sortLevel={sortLevel}
          onClick={onColumnClick}
        />
      </div>
    );
  }

  return (
    <div className="AlertTable-row">
      <div className="AlertTable-rowText">
        <AlertStatusIndicator status={status} />
      </div>
      <div className="AlertTable-badgeText">
        <AlertDashboardBadge type={type} />
      </div>
      <div className="AlertTable-rowText">
        <div>{faultDate}</div>
        <div className="AlertTable-rowText--subtext">{faultTime}</div>
      </div>
      <div className="AlertTable-rowText">{renderDuration()}</div>
      <div className="AlertTable-rowText">
        <div>{censor(locationBarnName, censorTypes.barn)}</div>
        <div className="AlertTable-rowText--subtext AlertTable-feedLine">
          {censor(locationFeedLine, censorTypes.feedline)}
        </div>
      </div>
      <div className="AlertTable-rowText">{renderNoFeedTime()}</div>
      <div className="AlertTable-badgeText">
        <AlertDashboardBadge type={rootCause} />
        {user?.isStaff && (
          <Link target="_blank" to={`/fault/${faultId}`}>
            🔎
          </Link>
        )}
      </div>
    </div>
  );
}

AlertTableRow.propTypes = {
  isHeader: PropTypes.bool,
  status: PropTypes.string,
  type: PropTypes.string,
  faultId: PropTypes.string,
  faultDate: PropTypes.string,
  faultTime: PropTypes.string,
  durationStartTime: PropTypes.number,
  durationEndTime: PropTypes.number,
  locationBarnName: PropTypes.string,
  locationFeedLine: PropTypes.string,
  noFeedTime: PropTypes.number,
  rootCause: PropTypes.string,
  sortColumn: PropTypes.string,
  sortLevel: PropTypes.number,
  onColumnClick: PropTypes.func,
};

export default AlertTableRow;
